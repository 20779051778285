/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

const $ = require('jquery');
global.$ = global.jQuery = $;
require('bootstrap');

import './styles/global.scss';
require('./js/fotorama.js');

import './js/bootstrap-datepicker';
import './javascript/bootstrap-datepicker.de';
import './javascript/bootstrap-datepicker.en';
import './javascript/bootstrap-datepicker.hu';
// import 'fotorama/fotorama.css';

import './styles/app.css';

import './styles/fa.css';
import './styles/ekko-lightbox.css';
import './styles/glyphicons-bootstrap.css';
import './styles/glyphicons-halflings.css';
import './styles/icomoon.css';
import 'bootstrap-colorpicker/dist/js/bootstrap-colorpicker.min.js';
import 'bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css';
import 'ekko-lightbox/dist/ekko-lightbox.min';
//import 'ekko-lightbox/dist/ekko-lightbox.css';
// import 'nicescroll/dist/jquery.nicescroll.min';
import 'magnific-popup/dist/jquery.magnific-popup.min';
import 'magnific-popup/dist/magnific-popup.css';
import 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';

$(document).ready(function () {
    $("[rel='tooltip']").tooltip();


    ////////// Back to Top //////////
    $(function() {
        $(window).scroll(function() {
            if($(this).scrollTop() != 0) {
                $('#toTop').fadeIn();
            } else {
                $('#toTop').fadeOut();
            }
        });

        $('#toTop').click(function() {
            $('body,html').animate({scrollTop:0},800);
        });
    });

    $(".alert").addClass("in").fadeOut(4500);
/*
    $('html').niceScroll({
        cursorcolor: "#000",
        cursorborder: "0px solid #fff",
        railpadding: {
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
        },
        cursorwidth: "10px",
        cursorborderradius: "0px",
        cursoropacitymin: 0.2,
        cursoropacitymax: 0.8,
        boxzoom: true,
        horizrailenabled: false,
        zindex: 9999
    });
*/
    // footer always on bottom
    var docHeight = $(window).height();
    var footerHeight = $('#main-footer').height();
    var footerTop = $('#main-footer').position().top + footerHeight;

    if (footerTop < docHeight) {
        $('#main-footer').css('margin-top', (docHeight - footerTop) + 'px');
    }



    $('.card-select > li').click(function () {
        var self = this;
        $(self).addClass('card-item-selected');
        $(self).siblings('li').removeClass('card-item-selected');
        $('.form-group-cc-number input').click(function () {
            $(self).removeClass('card-item-selected');
        });
    });
// Lighbox gallery
    $('#popup-gallery').each(function () {
        $(this).magnificPopup({
            delegate: 'a.popup-gallery-image',
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    });

// Lighbox image
    $('.popup-image').magnificPopup({
        type: 'image'
    });

// Lighbox text
    $('.popup-text').magnificPopup({
        removalDelay: 500,
        closeBtnInside: true,
        callbacks: {
            beforeOpen: function () {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true
    });

// Lightbox iframe
    $('.popup-iframe').magnificPopup({
        dispableOn: 700,
        type: 'iframe',
        removalDelay: 160,
        mainClass: 'mfp-fade',
        preloader: false
    });

    $('.form-group-select-num').each(function () {
        var self = $(this),
            btnGroup = self.find('.btn-group').first(),
            select = self.find('select');
        btnGroup.children('label').last().click(function () {
            btnGroup.addClass('hidden');
            select.removeClass('hidden');
        });

    });
    $('.form-group-select-plus').each(function () {
        var self = $(this),
            btnGroup = self.find('.btn-group').first(),
            select = self.find('select');
        btnGroup.children('label').last().click(function () {
            btnGroup.addClass('hidden');
            select.removeClass('hidden');
        });
    });
});